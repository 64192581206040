import React from "react"
import PropTypes from "prop-types"

const ScrollTo = ({ data }) => {
  return <div id={data.sectionName} />
}

ScrollTo.propTypes = {
  data: PropTypes.shape({
    sectionName: PropTypes.string,
  }),
}

export default ScrollTo
